
import {Util} from '@/util/Util';
import {ElExpress} from '@/util/ElExpress';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {BusinessCheckQuestion} from '@/components/question/selectionQuestion/BusinessCheckQuestion';
import {RadioYesNoQuestion} from '@/components/question/radionQuestion/RadioYesNoQuestion';
import {ComboQuestion} from '@/components/question/comboQuestions/ComboQuestion';
import {InputQuestion} from '@/components/question/inputQuestions/InputQuestion';
import {FileQuestion} from '@/components/question/fileQuestion/FileQuestion';
import {SelectionQuestion} from '@/components/question/selectionQuestion/SelectionQuestion';
import {GroupedBisnessQuestion} from '@/components/question/selectionQuestion/GroupedBisnessQuestion';

import {QuestionBoard} from '@/components/question/common/QuestionBoard';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class Questions {
    public static getObject(json: any): AbstractQuestion<object> | null {
        switch (json.viewType) {
            case 'SelectionQuestionView': {
                const question = new SelectionQuestion();
                return question.fromObject(json);
            }
            case 'BusinessSelectionView': {
                const question = new BusinessCheckQuestion();
                return question.fromObject(json);
            }
            case 'ComboQuestionView': {
                const question = new ComboQuestion();
                return question.fromObject(json);
            }
            case 'FileQuestionView': {
                const question = new FileQuestion();
                return question.fromObject(json);
            }
            case 'AreaInputQuestionView': {
                const question = new InputQuestion();
                return question.fromObject(json);
            }
            case 'InputQuestionView': {
                const question = new InputQuestion();
                return question.fromObject(json);
            }
            case 'RadioYesNoQuestionView': {
                const question = new RadioYesNoQuestion();
                return question.fromObject(json);
            }
            case 'GroupedBisnessQuestionView': {
                const question = new GroupedBisnessQuestion();
                return question.fromObject(json);
            }
            default:
                return null;
        }
    }

    public static updateQuestions(questions: QuestionBoard): void {
        for (const group of questions.questionGroups) {
            for (const question of group.questions) {
                question.ticketNeed = [];
                question.formNeed = [];
                if (Util.isEmptyOrNull(question.formNeedExpr) && Util.isEmptyOrNull(question.ticketNeedExpr)) {
                    continue;
                }
                if (!Util.isEmptyOrNull(question.formNeedExpr)) {
                    for (const name in question.formNeedExpr) {
                       if (question.formNeedExpr[name] instanceof Array) {
                           let result = false;
                           for (const expr of question.formNeedExpr[name]) {
                               if (result === true) {
                                   break
                               }
                               result = this.calcExpr(expr, questions)
                           }
                           if (result) {
                               question.formNeed.push(name);
                           }
                           continue
                       }
                       if (this.calcExpr(question.formNeedExpr[name], questions)) {
                           question.formNeed.push(name);
                       }
                    }
                }
                if (!Util.isEmptyOrNull(question.ticketNeedExpr)) {
                    for (const name in question.ticketNeedExpr) {
                        if (this.calcExpr(question.ticketNeedExpr[name], questions)) {
                            question.ticketNeed.push(name);
                        }
                    }
                }
            }
        }
    }

    public static loadAnswers(json: any, question: QuestionBoard): void {
        const version = json.questionVersion;
        const baseTime = json.baseDate;
        if (question.version !== parseInt(version)) {
            return
        }
        const content: any = json.answerContent;
        for (const group of question.questionGroups) {
            for (const questionItem of group.questions) {
                if (!Util.isEmptyOrNull(content[group.id])) {
                    // 当前问题已经被用户回答
                    if (!Util.isEmptyOrNull(content[group.id][questionItem.id])) {
                        const item = QuestionariesEntry.loadJson(content[group.id][questionItem.id]);
                        questionItem.accept(item);
                    }
                }
                questionItem.baseTime = baseTime;
                const visible = questionItem.visible && true;
                questionItem.visible = !visible;
                questionItem.visible = visible;
            }
        }
    }

    /**
     *  如果选项都没选就返回负1，不允许下一步
     */
    public static calcuteServicePrice(board: QuestionBoard): number {
        let price: number = 0;
        for (const gp of board.questionGroups) {
          for (const question of gp.questions) {
            const obj: any = question;
            if (question instanceof SelectionQuestion || question instanceof GroupedBisnessQuestion) {
                question.anySelectionRef();
                if (question.anySelected) {
                    if (question.price > 0)  {
                        price += question.price;
                    }
                }
            } else {
                if (question.price > 0)  {
                    price += question.price;
                }
            }
          }
        }
        return 500 + price;
    }

    public static calcExpr(expr: string, questionSet: QuestionBoard): boolean {
        const idGroupMap = questionSet.groupIdMap;
        const elExpr = new ElExpress(expr);
        const data: any = {};
        // 填充需要的数据
        elExpr.varList.forEach((varName) => {
            if (varName.indexOf('.') === -1) {
                data[varName] = idGroupMap[varName].visible;
            } else {
                const arrNames: string[] = varName.split('.');
                let targetdata: any = idGroupMap[arrNames[0]];
                if (Util.isEmptyOrNull(targetdata)) {
                    return;
                }
                targetdata = targetdata.questionIdMap;
                for (let idx = 1; idx < arrNames.length; idx ++) {
                    if (Util.isEmptyOrNull(targetdata[arrNames[idx]])) {
                        // console.log(elExpr);
                    }
                    targetdata = targetdata[arrNames[idx]];
                }
                data[varName] = targetdata;
            }
        });
        const result: any = new Function('{return (' + elExpr.spreadForFunc(data) + ');}')();
        return result === true;
    }

    /**
     * 提取需要存储的问题部分，方便后端进行解析和存储
     * @param {QuestionBoard} board
     * @returns {any}
     */
    public static prepareForEngagement(board: QuestionBoard): any {
    }
}

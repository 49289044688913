export class Util {
    public static isEmptyOrNull(object: any) {
        if (typeof object === 'undefined' ||
            object === '' ||
            object == null ||
            object === 'undefined' ||
            object === 'null' ||
            !/\S+/.test(object) ||
            object === undefined) {
            return true;
        } else {
            return false;
        }
    }
    public static trimString(src: string): string {
        return src.replace(/[\s]?/g, '');
    }
    public static deepCopy<T>(src: any, target: any): T {
        if (target === null || typeof target === 'undefined') {
            if (typeof src === 'string') {
                target = '' + src;
                return target;
            }
            if (Object.getPrototypeOf(src).constructor) {
                if (Object.getPrototypeOf(src).constructor.name === 'File') {
                    target = JSON.parse(JSON.stringify(src));
                } else {
                    const Constructor = Object.getPrototypeOf(src).constructor;
                    target = new Constructor();
                }
            } else {
                target = {};
            }
        }
        for (const prop in src) {
            if (src[prop] instanceof Array) {
                const arr = [];
                for (const elem of src[prop]) {
                    arr.push(this.deepCopy(elem, null));
                }
                target[prop] = arr;
            } else if (src[prop] instanceof Object) {
                target[prop] = this.deepCopy(src[prop], null);
            } else {
                target[prop] = src[prop];
            }
        }
        return target;
    }

    public static getCurrTaxYear(): number {
        return 2019;
    }

    public static getCurrYear(baseTime: any): number {
        let curr: Date = new Date();
        if (baseTime !== undefined && baseTime != null && baseTime > 0) {
            curr = new Date(baseTime);
        }
        let year = curr.getFullYear();
        const month = curr.getMonth() + 1; // month会从0开始计算
        const day = curr.getDate();
        if (month < 4 && day < 15) {
            year = year - 1;
        }
        return year;
    }

    public static of<T>(item: any, orElse: T): T {
        if (Util.isEmptyOrNull(item)) {
            return orElse;
        }
        if (item === undefined) {
            return orElse;
        }
        if (item === null) {
            return orElse;
        }
        return item;
    }

    public static parseNumber(str: string): number {
        return Number(str);
    }

}

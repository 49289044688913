import {Util} from '@/util/Util';
import {ListableProperty} from '@/util/annotation/DataDescriptor';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class SelectionQuestion extends AbstractQuestion<SelectionQuestion> {

    public label: string = 'default';

    @ListableProperty
    public currentYearSelection: boolean = false;

    @ListableProperty
    public prevYearSelection: boolean = false;

    @ListableProperty
    public prevTwoYearsSelection: boolean = false;

    public anySelected: boolean = false;

    public expandName: string|null = null;

    public fromObject(source: any): SelectionQuestion {
        Util.deepCopy(source, this);
        return this;
    }

    public anySelectionRef(): void {
        this.price = 0;
        if (this.currentYearSelection === true) {
            this.price++;
        }
        if (this.prevYearSelection === true) {
            this.price++;
        }
        if (this.prevTwoYearsSelection === true) {
            this.price++;
        }
        this.price = this.onceServicePrice * this.price;
        this.anySelected = this.currentYearSelection || this.prevYearSelection
           || this.prevTwoYearsSelection;
    }

    public getCopy(): object {
        return {
          label: this.label,
          currentYearSelection: this.currentYearSelection,
          prevYearSelection: this.prevYearSelection,
          prevTwoYearsSelection: this.prevTwoYearsSelection,
          onceServicePrice: this.onceServicePrice,
          anySelected: this.anySelected,
          triggerEl: this.trigger,
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const ent = new QuestionariesEntry(comp).fromObject(this);
        this.anySelectionRef();
        if (!this.anySelected) {
            ent.useful = false;
            return ent;
        }
        const obj: any = this;
        for (const item of obj.props) {
            if (obj[item]) {
                ent.selectedYears.push(item)
            }
        }
        return ent;
    }

    public accept(answer: QuestionariesEntry): void {
        const obj: any = this;
        for (const item of answer.selectedYears) {
            obj[item] = true;
        }
        this.anySelectionRef();
    }

    private label_currentYearSelection(): string {
        return Util.getCurrTaxYear() + '';
    }

    private label_prevTwoYearsSelection(): string {
        return (Util.getCurrTaxYear() - 2) + '';
    }

    private label_prevYearSelection(): string {
        return (Util.getCurrTaxYear() - 1) + '';
    }

    private label_moreThanCurr(comp: any): string {
        return Util.getCurrTaxYear() + ' : ' + comp.$t('quickQuote.A3');
    }

    private label_moreThan1Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 1) + ' : ' + comp.$t('quickQuote.A3');
    }

    private label_moreThan2Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 2) + ' : ' + comp.$t('quickQuote.A3');
    }
}

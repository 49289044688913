import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {IDataObject} from '@/util/IDataObject';
import {Questions} from '@/components/question/common/Questions';

export class QuestionGroup implements IDataObject<QuestionGroup> {

    public static create(json: any): QuestionGroup {
        const result = new QuestionGroup();
        result.fromObject(json);
        result.questions = result.questions.map((question) => {
            const questionItem = Questions.getObject(question);
            if (questionItem === null) {
                throw new Error('Question 不应该为空, 可能是类型错误。');
            }
            if (!Util.isEmptyOrNull(questionItem.id)) {
                result.questionIdMap[questionItem.id] = questionItem;
            }
            return questionItem;
        });
        return result;
    }

    public id: string = '';
    public questionIdMap: any = {};
    public visible: boolean = true;
    public trigger: string = '';
    public questions: Array<AbstractQuestion<object>> = [];

    public add(question: AbstractQuestion<object>): void {
        if (!Util.isEmptyOrNull(question.id)) {
            this.questionIdMap[question.id] = question;
        }
        this.questions.push(question);
    }

    public fromObject(source: any): QuestionGroup {
        Util.deepCopy(source, this);
        return this;
    }

    public getCopy(): object {
        return {
            name: this.id,
            questions: Util.deepCopy(this.questions, null),
        };
    }

}

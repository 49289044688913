import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {ListableProperty} from '@/util/annotation/DataDescriptor';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class BusinessCheckQuestion extends AbstractQuestion<object> {

    @ListableProperty
    public soleProprietor: boolean = false;

    @ListableProperty
    public singleMemberLLC: boolean = false;

    @ListableProperty
    public multipleMemberLLC: boolean = false;

    @ListableProperty
    public partnership: boolean = false;

    @ListableProperty
    public sCorporation: boolean = false;

    @ListableProperty
    public uSCorporation: boolean = false;

    @ListableProperty
    public foreignCorporation: boolean = false;

    public label: string = 'default';

    public fromObject(source: any): BusinessCheckQuestion {
        Util.deepCopy(source, this);
        return this;
    }

    public getCopy(): object {
        return {
            label: this.label,
            soleProprietor: this.soleProprietor,
            singleMemberLLC: this.singleMemberLLC,
            multipleMemberLLC: this.multipleMemberLLC,
            partnership: this.partnership,
            sCorporation: this.sCorporation,
            uSCorporation: this.uSCorporation,
            foreignCorporation: this.foreignCorporation,
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const ent = new QuestionariesEntry(comp).fromObject(this); const content: string[] = [];
        const obj: any = this;
        for (const item of obj.props) {
            if (obj[item]) {
                content.push(item);
            }
        }
        if (content.length == 0) {
            ent.useful = false;
            return ent;
        }
        ent.content = content;
        return ent;
    }

    public accept(answer: QuestionariesEntry): void {
        const obj: any = this;
        for (const prop of obj.props) {
            obj[prop] = true
        }
    }
    private label_incomeCurrentYear(comp: any): string {
        const year = Util.getCurrTaxYear() - 0;
        return comp.$t('quickQuote.A7-1') + '  ' + year;
    }

    private label_income2YearAgo(comp: any): string {
        const year = Util.getCurrTaxYear() - 1;
        return comp.$t('quickQuote.A7-1')  + '  ' + year;
    }

    private label_income3YearAgo(comp: any): string {
        const year = Util.getCurrTaxYear() - 2;
        return comp.$t('quickQuote.A7-1') + '  ' + year;
    }
}

























































































































































































import {Prop, Component, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class GroupedBisnessQuestionView extends Vue {
    @Prop()
    protected readonly question!: any;

    @Prop()
    protected readonly emitTarget!: string;

    protected onA20Click() {
        const modal: any = this.$refs['a20-modal'];
        modal.show();
    }

    protected hideA20Modal() {
        const modal: any = this.$refs['a20-modal']
        modal.hide();
    }

    @Watch('question.templateFieldsData', {deep: true})
    protected onTemplateDataChange(valNew, valOld) {
        this.question.anySelectionRef();
        if (this.question.templateFields.type === 'CheckBox') {
            for (const item of this.question.templateFields.fieldList) {
                let anySelected = false;
                for (const prop in valNew) {
                    if (!anySelected) {
                        anySelected = valNew[prop][item]
                    }
                }
                this.question.templateFieldsStatus[item] = anySelected;
            }
        } else if (this.question.templateFields.type === 'Selection') {
        }
    }

    @Watch('question.currentYearSelection')
    protected onYearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

    @Watch('question.prevYearSelection')
    protected onPrev1YearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

    @Watch('question.prevTwoYearsSelection')
    protected onPrev2YearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

}

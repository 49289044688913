import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class ComboQuestion extends AbstractQuestion<object> {

    public options: string[] = [];

    public viewOptions: any[] = [];

    public label: string = 'default';

    public selected: any = {};

    public fromObject(source: any): ComboQuestion {
        Util.deepCopy(source, this);
        this.options.forEach((str) => {
            this.viewOptions.push({
                value: str, text: str,
            });
        });
        return this;
    }

    public getCopy(): object {
        return {
            options: Util.deepCopy(this.options, null),
            label: this.label,
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const data = new QuestionariesEntry(comp).fromObject(this);
        data.content = Util.deepCopy(this.selected, {});
        if (this.selected == null) {
            data.useful = false;
        }
        return data;
    }

    public accept(answer: QuestionariesEntry): void {
        this.selected = Util.deepCopy(answer.content, {});
    }

    private label_exceedCurr(comp: any): string {
        return comp.$t('quickQuote.A20-1') + Util.getCurrTaxYear() + comp.$t('quickQuote.A20-2');
    }

    private label_exceed1YearAgo(comp: any): string {
        return comp.$t('quickQuote.A20-1') + (Util.getCurrTaxYear() - 1) + comp.$t('quickQuote.A20-2');
    }

    private label_exceed2YearAgo(comp: any): string {
        return comp.$t('quickQuote.A20-1') + (Util.getCurrTaxYear() - 2) + comp.$t('quickQuote.A20-2');
    }
}

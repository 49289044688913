import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class FileQuestion extends AbstractQuestion<FileQuestion> {

    public label: string = 'default';

    public content: any = null;

    public fromObject(source: any): FileQuestion {
        Util.deepCopy(source, this);
        return this;
    }

    public getCopy(): object {
        return {
            label: this.label,
            content: this.content,
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const data = new QuestionariesEntry(comp).fromObject(this);
        data.content = this.content;
        return data;
    }

    public accept(answer: QuestionariesEntry): void {
        this.content = answer.content;
    }
}
















































import {Prop, Component, Vue} from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class QuestionView extends Vue {
    @Prop()
    protected readonly question!: any;

    @Prop()
    protected readonly emitTarget!: string;
}





























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import QuestionGroupView from '../components/question/common/view/QuestionGroupView.vue';
import {QuestionBoard} from '../components/question/common/QuestionBoard';
import QuestionBoardView from '../components/question/common/view/QuestionBoardView.vue';
import {QuestionGroup} from '../components/question/common/QuestionGroup';
import {Questions} from '../components/question/common/Questions';
import {User} from '../components/common/user/User';
import {Util} from '../util/Util';
import { firebase } from '../util/firebase';
import 'firebase/storage';
import axios from 'axios';
import StripePay from './StripePay.vue'
import {QuestionariesDocument} from '../components/question/QuestionariesDocument';
import PubSub from 'pubsub-js';
import * as SurveyVue from 'survey-vue'
import 'survey-vue/survey.css'
import * as SurveyKO from 'survey-knockout'
import { openDB, deleteDB } from 'idb';

const Survey = (SurveyVue as any).Survey

declare let gtag: Function;

@Component<Home>({
  components: {
      QuestionBoardView,
      QuestionGroupView,
      StripePay,
      Survey,
  },
  metaInfo() {
    return {
      title: this.metaTitle || 'Tax Return Preparation Quote',
      meta: [{ name: 'description', content: this.metaDescription || '' }],
    }
  },
})
export default class Home extends Vue {

    protected questionBoard: QuestionBoard = new QuestionBoard();

    protected user: User = new User();

    protected step: number = 0;

    protected nextStepLoading: boolean = false;

    protected years: string = '';

    protected price: string = '$0';

    protected contactId: string = '';

    protected engagementId: string = '';

    protected engagementStatus: string = '';

    protected fullName: string = '';

    protected spouseFullName: string = '';

    protected envelopeId: string = '';

    protected isEmailExists: boolean = false;

    protected comment: string = '';

    protected contactForm: any = {
        email: '',
        user_password: '',
        user_repeat_password: '',
        firstname: '',
        middlename: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone1: '',
        phone2: '',
        spouse: {
          firstname: '',
          middlename: '',
          lastname: '',
        },
    };

    protected urlContact: any = {};

    protected system_config: any = {
      quickquote_title: 'Quick Quote',
      firm_name: '',
      docusign_enabled: true,
      taxreturn_agreement: '',
    };

    protected ui_settings: any = {
      primary_logo_url: '',
      secondary_logo_url: '',
      tertiary_logo_url: '',
      background_logo_url: '',
    };

    /*
        All of the form controls support a state prop, which can be used to set the form control into one of three contextual states:
            false (denotes invalid state) is great for when there's a blocking or required field. A user must fill in this field properly to submit the form.
            true (denotes valid state) is ideal for situations when you have per-field validation throughout a form and want to encourage a user through the rest of the fields.
            null Displays no validation state (neither valid nor invalid)
    */
    protected emailState: any = null;

    protected emailWarningText: string = '';

    protected emailRe: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    protected passwordState: any = null;

    protected passwordWarningText: string = '';

    protected repeatPasswordState: any = null;

    protected repeatPasswordWarningText: string = '';

    protected firstNameState: any = null;

    protected firstNameWarningText: string = '';

    protected lastNameState: any = null;

    protected lastNameWarningText: string = '';

    protected quickQuoteFiles: any = null;

    protected survey: any = null;

    protected surveyId: any = null;

    protected surveyName: any = null;

    protected isAcceptAgreement: boolean = false;

    protected metaTitle: any = null;

    protected metaDescription: any = null;

    protected isSignInWith: any = false;

    protected storageName: any = 'qqSurveyPrevData';

    protected indexedDBObject: any = null;

    protected async created() {
        this.nextStepLoading = false;
        firebase.firestore().collection('system_config').doc('quickquote.title').get().then((doc) => {
          this.system_config.quickquote_title = doc.data().value;
        })
        firebase.firestore().collection('system_config').doc('firm.name').get().then((doc) => {
          this.system_config.firm_name = doc.data().value;
        })
        PubSub.subscribe('uploadedFile', (event, data) => {
            this.quickQuoteFiles = data.fileList;
        })
        axios({
          method: 'post',
          url: '/getUISettings',
        }).then((resp) => {
          for (const item of resp.data) {
            if (item.id == 'color.primary') {
              document.documentElement.style.setProperty('--primary-color', item.value)
            } else if (item.id == 'color.secondary') {
              document.documentElement.style.setProperty('--secondary-color', item.value)
            } else if (item.id == 'color.tertiary') {
              document.documentElement.style.setProperty('--tertiary-color', item.value)
            } else if (item.id == 'color.alternative') {
              document.documentElement.style.setProperty('--alternative-color', item.value)
            } else if (item.id == 'logo.primary') {
              this.ui_settings.primary_logo_url = item.value;
            } else if (item.id == 'logo.secondary') {
              this.ui_settings.secondary_logo_url = item.value;
            } else if (item.id == 'logo.tertiary') {
              this.ui_settings.tertiary_logo_url = item.value;
            } else if (item.id == 'logo.background') {
              this.ui_settings.background_logo_url = item.value;
            } 
            // else if (item.id == 'font.family') {
            //   document.documentElement.style.setProperty('--sv-main-font-family', item.value)
            // }
          }
        })
        /*QuestionService.getQuestions({version: -1})
            .then((result) => this.resolveQuestions(result));*/
        // this.resolveQuestions(require('./questions.json'));
        // firebase.firestore().collection("questionaries").doc("5UMz3o2PsOTYw635VSGI").get().then(doc => {
        //   console.log('docccccccc', doc.data().content)
        //   this.survey = new SurveyVue.Model(doc.data().content);
        // })
        await this.loadQuestionDefinition();
        // 完成签名返回tpa，通过url中的参数判断是否签名成功，签名成功就跳到step4，调用StripePay组件
        if (this.getQueryValue('dsreturn') && this.getQueryValue('event') == 'signing_complete') {
            if (this.getQueryValue('need_spouse_sign')) {
                this.step = 3;
                this.envelopeId = Util.of(sessionStorage.getItem('envelopeId'), '');
                this.contactId = Util.of(sessionStorage.getItem('contactId'), '');
                this.engagementId = Util.of(sessionStorage.getItem('engagementId'), '');
                const contactDocRef = firebase.firestore().collection('contacts').doc(this.contactId);
                contactDocRef.get().then((contactDoc) => {
                    const contactDocData = contactDoc.data();
                    const contactSpouse = contactDocData.spouse;
                    this.contactForm = contactDocData;
                    this.fullName = this.getFullName(contactDocData.firstname, contactDocData.middlename, contactDocData.lastname);
                    if (contactSpouse) {
                      this.spouseFullName = this.getFullName(contactSpouse.firstname, contactSpouse.middlename, contactSpouse.lastname);
                    }
                    sessionStorage.setItem('fullName', this.fullName);
                    contactDocRef.collection('engagements').doc(this.engagementId).get().then((engagementDoc) => {
                        this.years = engagementDoc.data().years;
                        this.price = engagementDoc.data().total_price;
                        this.comment = engagementDoc.data().comment;
                        if (!this.comment) {
                            contactDocRef.collection('engagements').doc(this.engagementId).collection('questionaries').where('owner_uid', '==', this.contactId).get().then((querySnapshot) => {
                                this.comment = querySnapshot.docs[0].data().clientComment;
                            })
                        }
                        this.nextStepLoading = false;
                    })
                })
            } else {
                this.step = 4;
                this.fullName = Util.of(sessionStorage.getItem('fullName'), '');
                const contactId = Util.of(sessionStorage.getItem('contactId'), '');
                const engagementId = Util.of(sessionStorage.getItem('engagementId'), '');
                firebase.firestore().collection('contacts').doc(contactId).collection('engagements').doc(engagementId).get().then((doc) => {
                    this.price = '$' + parseInt(doc.data().total_price.substring(1))
                })
                firebase.firestore().collection('contacts').doc(contactId).collection('engagements').doc(engagementId).set({
                    status: 'DocuSigned',
                }, {merge: true});
            }
        } else if (this.getQueryValue('resume')) {
            // 客户通过邮件链接返回tpa，通过url中的参数获取price和comment显示在step3中
            const self: any = this;
            this.step = 3;
            this.nextStepLoading = true;
            self.contactForm.email = decodeURIComponent(Util.of(this.getQueryValue('em'), ''));
            self.contactId = Util.of(this.getQueryValue('conId'), '');
            self.engagementId = Util.of(this.getQueryValue('engaId'), '');
            sessionStorage.setItem('email', self.contactForm.email);
            sessionStorage.setItem('contactId', self.contactId);
            sessionStorage.setItem('engagementId', self.engagementId);
            axios({
                method: 'post',
                url: '/getUserByEmail',
                data: {
                    email: self.contactForm.email,
                },
            }).then((response) => {
                const uid = response.data.user.uid;
                axios({
                    method: 'post',
                    url: '/generateCustomToken',
                    data: {
                        uid,
                    },
                }).then((response) => {
                    firebase.auth().signInWithCustomToken(response.data.token).then((response) => {
                        firebase.firestore().collection('system_config').doc('docusign.enabled').get().then((doc) => {
                          this.system_config.docusign_enabled = doc.data().value;
                        })
                        firebase.firestore().collection('system_config').doc('taxreturn.agreement').get().then((doc) => {
                          this.system_config.taxreturn_agreement = doc.data().value;
                        })
                        const contactDocRef = firebase.firestore().collection('contacts').doc(this.contactId);
                        contactDocRef.get().then((contactDoc) => {
                            const contactDocData = contactDoc.data();
                            const contactSpouse = contactDocData.spouse;
                            this.fullName = this.getFullName(contactDocData.firstname, contactDocData.middlename, contactDocData.lastname);
                            if (contactSpouse) {
                              this.spouseFullName = this.getFullName(contactSpouse.firstname, contactSpouse.middlename, contactSpouse.lastname);
                            }
                            sessionStorage.setItem('fullName', this.fullName);
                            contactDocRef.collection('engagements').doc(this.engagementId).get().then(async (engagementDoc) => {
                                this.engagementStatus = engagementDoc.data().status;
                                // 如果已完成签名，就去往付款页面
                                if (this.engagementStatus == 'DocuSigned') {
                                    this.step = 4;
                                    firebase.firestore().collection('contacts').doc(this.contactId).collection('engagements').doc(this.engagementId).get().then((doc) => {
                                        this.price = '$' + parseInt(doc.data().total_price.substring(1))
                                    })
                                    this.nextStepLoading = false;
                                    return;
                                }
                                // 如果已完成付款，就登录pacioli2
                                if (this.engagementStatus == 'DepositPaid') {
                                    this.step = 5;
                                    this.nextStepLoading = false;
                                    return;
                                }
                                // 如果已激活，就登录pacioli2
                                if (this.engagementStatus == 'Active') {
                                    this.step = 6;
                                    this.nextStepLoading = false;
                                    return;
                                }
                                // 如果engagement状态为Reviewed（正常），就正常执行签名付款流程
                                if (this.engagementStatus == 'Reviewed') {
                                    this.years = engagementDoc.data().years;
                                    this.price = engagementDoc.data().total_price;
                                    this.comment = engagementDoc.data().comment;
                                    this.surveyId = engagementDoc.data().survey_id;
                                    const surveyDoc = await firebase.firestore().collection('questionaries').doc(this.surveyId).get()
                                    const surveyName = surveyDoc.data().name
                                    gtag('event', 'add_to_cart', {
                                      items: [
                                        {
                                          id: this.contactId,
                                          name: surveyName,
                                          list_name: 'not set',
                                          brand: 'global-accountant',
                                          category: 'not set',
                                          variant: 'not set',
                                          list_position: 1,
                                          quantity: this.years,
                                          price: this.price,
                                          checkout_option: 'alternative',
                                        },
                                      ],
                                    });
                                    contactDocRef.collection('engagements').doc(this.engagementId).get().then((querySnapshot) => {
                                        this.envelopeId = querySnapshot.data().envelopeId || ''
                                    });
                                    if (!this.comment) {
                                        contactDocRef.collection('engagements').doc(this.engagementId).collection('questionaries').where('owner_uid', '==', this.contactId).get().then((querySnapshot) => {
                                            this.comment = querySnapshot.docs[0] ? querySnapshot.docs[0].data().clientComment : '';
                                        })
                                    }
                                } else {
                                    // 如果状态不正常就联系管理员
                                    this.step = 7;
                                }
                                this.nextStepLoading = false;
                            })
                        })
                    })
                }).catch((error) => {
                    this.nextStepLoading = false;
                })
            }).catch((error) => {
                this.nextStepLoading = false;
            })
        }
        (this as any).$analytics.logEvent('quote_request_shown');
    }

    private getQueryValue(queryName: any) {
        const query = decodeURI(window.location.search.substring(1));
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] == queryName) {
                return pair[1];
            }
        }
        return null;
    }

    /**
    * database64文件格式转换为2进制
    *
    * @param  {[String]} data dataURL 的格式为 “data:image/png;base64,****”,逗号之前都是一些说明性的文字，我们只需要逗号之后的就行了
    * @param  {[String]} mime [description]
    * @return {[blob]}      [description]
    */
    private data2blob(data, mime) {
      data = data.split(',')[1]
      data = window.atob(data)
      const ia = new Uint8Array(data.length)
      for (let i = 0; i < data.length; i++) {
        ia[i] = data.charCodeAt(i)
      }
      // canvas.toDataURL 返回的默认格式就是 image/png
      return new Blob([ia], {
        type: mime,
      })
    }

    private async uploadFiles(contactId, engagementId, answerJSONStr) {
        let answer = JSON.parse(answerJSONStr || '')
        let fileList = [];
        this.getOrRemoveFilesFromResultJson(answer, fileList);
        await this.doUploadFiles(contactId, engagementId, fileList)
    }

    private async doUploadFiles(contactId, engagementId, fileList) {
        if (fileList.length == 0) {
          return;
        }
        for (const file of fileList) {
          if (file.name && file.content) {
            // Upload file and metadata to the object 'images/demo2.jpg'
            // path: 'engagements/{contactId}/{engagementId}/files/{fileName}'
            await firebase.storage().ref().child('engagements' + '/' + contactId + '/' + engagementId + '/files/' + file.name).put(this.data2blob(file.content, file.type))
          } else {
            await this.doUploadFiles(contactId, engagementId, file)
          }
        }
    }

    private resolveQuestions(data: any): void {
        this.questionBoard.version = parseFloat(data.version);
        const content = data.content;
        for (const item of content) {
            this.questionBoard.add(QuestionGroup.create(item));
        }
        if (!Util.isEmptyOrNull(this.$route.params.ctxid)) {
            console.log('load answers');
        }
    }

    private async loadQuestionDefinition(): Promise<any> {
        this.surveyName = 'QQ-TAXPREP';
        if (this.$route.name == 'searchedHome') {
            this.surveyName = 'QQ-' + this.$route.params.qqtype.toUpperCase();
            this.metaTitle = 'Quote - ' + this.$route.params.qqtype.toUpperCase();
        }
        let collection = await firebase.firestore().collection('questionaries')
          .where('name', '==', this.surveyName)
          .get();
        if (!collection.docs[0]) {
            collection = await firebase.firestore().collection('questionaries')
              .where('name', '==', 'QQ-TAXPREP')
              .get();
        }
        const doc = await firebase.firestore()
            .collection('questionaries')
            .doc(collection.docs[0].id)
            .get()
        this.surveyId = collection.docs[0].id
        const data = doc.data().content;

        const coll = await firebase.firestore().collection('tax_forms').get();
        const taxFormPrice: any[] = []
        for (const doc of coll.docs) {
          taxFormPrice.push(doc.data())
        }

        const dataObject = JSON.parse(data)
        this.metaDescription = dataObject.description;
        this.survey = new SurveyVue.Model(data);
        // Restore Answered Questions for In-Completed Survey
        // https://surveyjs.io/Documentation/Library?id=LibraryOverview#data-restoreanswers
        this.survey.sendResultOnPageNext = true;
        this.survey.onPartialSend.add((survey) => {
          this.saveSurveyData(survey);
        });
        const prevData = window.localStorage.getItem(this.storageName) || null;
        if (prevData) {
          const _data = JSON.parse(prevData);
          this.survey.data = _data;
          if (_data.pageNo) {
            this.survey.currentPageNo = _data.pageNo;
          }
        }
        this.survey.onComplete.add(async (sender, options) => {
            const totalArr: any[] = []
            for (const page of dataObject.pages) {
              Object.keys(sender.data).map((qName) => {
                for (const element of page.elements) {
                  if (element.name == qName) {
                    if (element.type == 'checkbox') {
                      for (const choice of element.choices) {
                        if (sender.data[qName].indexOf(choice.value) != -1 && choice.taxForm) {
                          totalArr.push(choice.taxForm)
                        }
                      }
                    } else {
                      if (sender.data[qName] && element.taxForm) {
                        totalArr.push(element.taxForm)
                      }
                    }
                  }
                }
              })
            }

            let totalPrice = 0;
            for (const tax of taxFormPrice) {
              for (const total of totalArr) {
                if (tax.name == total) {
                  totalPrice += parseInt(tax.price)
                }
              }
            }
            this.step++;
            this.nextStepLoading = false;
            this.price = '$' + (totalPrice ? totalPrice : 0.01);
            await deleteDB('surveyAnswerDB');
            this.indexedDBObject = await openDB('surveyAnswerDB', 1, {
              upgrade(db) {
                db.createObjectStore('surveyAnswerStore', {
                  keyPath: 'name'
                });
              }
            });
            await this.indexedDBObject.add('surveyAnswerStore', { name: 'surveyAnswer', content: JSON.stringify(sender.data) });
            this.saveSurveyData(sender);
        });
    }

    private saveSurveyData(survey): void {
      const clonedData: any = Util.deepCopy(survey.data, {});
      clonedData.pageNo = survey.currentPageNo;
      this.getOrRemoveFilesFromResultJson(clonedData, false);
      window.localStorage.setItem(this.storageName, JSON.stringify(clonedData));
    }

    private getOrRemoveFilesFromResultJson(result, fileList): any {
      for (const objectKey in result) {
        if (result[objectKey].length) {
          let isApplication = false;
          for (const index in result[objectKey]) {
            const item = result[objectKey][index];
            if (item && item.content && item.content.indexOf('base64') != -1) {
              isApplication = true;
            } else if (typeof item == 'object') {
              this.getOrRemoveFilesFromResultJson(item, fileList);
            }
          }
          if (isApplication) {
            if (fileList && fileList instanceof Array) {
              fileList.push(result[objectKey]);
            } else {
              delete result[objectKey];
            }
          }
        }
      }
    }

    private prevStep(): void {
        this.step --;
    }

    private async onPaymentCompleted(surveyName, years): Promise<any> {
        this.step = 5;
        const price = this.price;
        (this as any).$analytics.logEvent('Paid_deposit', {
          value: price,
        });
        gtag('event', 'purchase', {
          transaction_id: this.engagementId,
          value: this.price,
          currency: "USD",
          tax: 0,
          shipping: 0,
          checkout_option: 'stripe',
          items: [
            {
              id: this.contactId,
              name: surveyName,
              list_name: 'not set',
              brand: 'global-accountant',
              category: 'not set',
              variant: 'not set',
              list_position: 1,
              quantity: years,
              price: this.price,
            },
          ],
        });
    }

    private toHomePage(): void {
      this.nextStepLoading = true;
      window.location.reload(true);
    }

    private toStripePayPage(): void {
      this.step = 4;
      this.fullName = Util.of(sessionStorage.getItem('fullName'), '');
      const contactId = Util.of(sessionStorage.getItem('contactId'), '');
      const engagementId = Util.of(sessionStorage.getItem('engagementId'), '');
      firebase.firestore().collection('contacts').doc(contactId).collection('engagements').doc(engagementId).get().then((doc) => {
          this.price = '$' + parseInt(doc.data().total_price.substring(1))
      })
      firebase.firestore().collection('contacts').doc(contactId).collection('engagements').doc(engagementId).set({
          status: 'DocuSigned',
      }, {merge: true});
    }

    private toPacioli2Questions(): void {
        this.nextStepLoading = true;
        window.location.href = process.env.VUE_APP_URL_PREFIX_ADMIN + '/#/questions?engaId=' + sessionStorage.getItem('engagementId') + '&conId=' + sessionStorage.getItem('contactId');
    }

    private toPacioli2(): void {
        this.nextStepLoading = true;
        window.location.href = process.env.VUE_APP_URL_PREFIX_ADMIN;
    }

    private getFullName(firstName: string, middleName: string, lastName: string): any {
        if (firstName || middleName || lastName) {
            return middleName ? (firstName + ' ' + middleName + ' ' + lastName) : (firstName + ' ' + lastName);
        } else {
            return '';
        }
    }
    /**
     * 去往签名文档页面，签名完成后会返回一个url，跳转到此url（刷新tpa）
     */
    private toDocusign(email: string, fullname: string, spouseFullName: string, contactId: string, engagementId: string, envelopeId: string): void {
        this.nextStepLoading = true;
        axios({
          method: 'post',
          url: '/openSigningCeremonyController',
          data: {
            USER_FULLNAME: fullname,
            SPOUSE_FULLNAME: spouseFullName,
            USER_EMAIL: email,
            YEARS: this.years,
            PRICE: this.price,
            BASE_URL: window.location.origin,
            ENVELOPE_ID: envelopeId,
            CLIENT_USER_ID: contactId,
            SURVEY_ID: this.surveyId,
          },
        }).then((response) => {
            if (response.data.envelopeId) {
                firebase.firestore().collection('contacts').doc(contactId).collection('engagements').doc(engagementId).set({
                    envelopeId: response.data.envelopeId,
                }, {merge: true});
            }
            // 将contactId和engagementId保存在sessionStorage中，方便StripePay调用
            sessionStorage.setItem('contactId', contactId);
            sessionStorage.setItem('engagementId', engagementId);
            sessionStorage.setItem('envelopeId', response.data.envelopeId);
            window.location.href = response.data.url;
        }).catch((error) => {
            console.log('DocusignError', error)
            this.nextStepLoading = false;
        })
    }

    /**
     * 准备questionaries的数据，将用户选择的问题保存在contact->engagements->questionaries中
     */
    private prepareForEngagementQuestionariesDoc(owner_uid: string, content: any): any {
        const result = {
            name: 'Quick Quote',
            content,
            created_on: new Date().toUTCString(),
            owner_uid,
        };
        return result;
    }

    /**
     * 保存engagement和questionaries
     */
    private async createEngagementAndQuestionaries(owner_uid: any, email: string, fullname: string, _step: any): Promise<any> {
        const contactDocRef = firebase.firestore().collection('contacts').doc(owner_uid);
        const engagementCollection = firebase.firestore().collection('contacts').doc(owner_uid).collection('engagements');
        const surveyAnswerData = await this.indexedDBObject.get('surveyAnswerStore', 'surveyAnswer');
        let quickQuoteContentObject = JSON.parse(surveyAnswerData.content || '{}');
        this.getOrRemoveFilesFromResultJson(quickQuoteContentObject, false);
        const questionariesDoc = this.prepareForEngagementQuestionariesDoc(owner_uid, quickQuoteContentObject);
        let reviewerUsername = this.$route.params.username;
        /**
        engagement添加source字段，两个值，frontend和backend
        frontend意味着这个Engagement是从tpaweb，由用户通过quickquote创建的。
        backend意味着这个Engagement是从pacioli2后台直接创建的。
        这个source是什么值，决定发哪种邮件。
         */
        const engagementDoc = {
            name: fullname + ' ' + Util.getCurrYear(null),
            total_price: this.price,
            status: 'New',
            created_on: new Date().toUTCString(),
            owner_uid,
            source: 'frontend',
            survey_id: this.surveyId,
        };
        engagementCollection.add(engagementDoc).then(async (addedEngagementDocRef) => {
            (this as any).$analytics.logEvent('engagement_started');
            this.contactId = owner_uid;
            this.engagementId = addedEngagementDocRef.id;
            if (!reviewerUsername) {
              reviewerUsername = 'edparsonscpa'
            }
            let querySnapshot: any;
            const defaultCPA = await firebase.firestore().collection('system_config').doc('default.cpa').get();
            if ((defaultCPA as any).data().value) {
              querySnapshot = await firebase.firestore().collection('contacts').where(firebase.firestore.FieldPath.documentId(), '==', (defaultCPA as any).data().value).get();
            } else {
              querySnapshot = await firebase.firestore().collection('contacts').where('username', '==', reviewerUsername).get();
            }
            if (querySnapshot.docs.length == 0) {
              querySnapshot = await firebase.firestore().collection('contacts').where('username', '==', 'edparsonscpa').get();
            }
            const email: any[] = [];
            for (const item of querySnapshot.docs) {
              this.urlContact = item.data()
              this.urlContact.id = item.id
              if (this.urlContact.email && email.indexOf(this.urlContact.email) == -1) {
                  email.push(this.urlContact.email);
              }
            }
            axios({
                method: 'post',
                url: '/sendReviewMailToAdmin',
                data: {
                    email,
                    contactId: this.contactId,
                    engagementId: this.engagementId,
                    engagementName: engagementDoc.name,
                    userFullName: fullname,
                },
            }).then((response) => {
                (this as any).$analytics.logEvent('quote_requested');
            });

            contactDocRef.set({
                    last_engagement: {
                      id: addedEngagementDocRef.id,
                      name: engagementDoc.name,
                      created_on: new Date().toUTCString(),
                    },
                }, {merge: true},
            )
            // assign to reviewer
            firebase.firestore().collection('contacts').doc(this.urlContact.id).collection('assigneed-engagements').add({
              contactId: this.contactId,
              engagementId: this.engagementId,
              engagementName: engagementDoc.name,
              contactName: fullname,
            })
            // assign to creator
            contactDocRef.collection('assigneed-engagements').add({
              contactId: this.contactId,
              engagementId: this.engagementId,
              engagementName: engagementDoc.name,
              contactName: fullname,
            })
            let reviewerAvatarUrl = 'https://image.flaticon.com/icons/svg/761/761824.svg'
            let ownerAvatarUrl = 'https://image.flaticon.com/icons/svg/761/761824.svg'
            // add reviewer and creator to engagement member
            if (this.urlContact.avatar_file_name) {
              const path = 'avatar/' + this.urlContact.id + '/files/' + this.urlContact.avatar_file_name
              reviewerAvatarUrl = await this.getFileUrl(path)
            }
            const contactDoc = await contactDocRef.get();
            if (contactDoc.data().avatar_file_name) {
              const path = 'avatar/' + this.contactId + '/files/' + contactDoc.data().avatar_file_name
              ownerAvatarUrl = await this.getFileUrl(path)
            }
            // add salesSource(reviewer username)
            engagementCollection.doc(addedEngagementDocRef.id).set({
              sales_source: this.urlContact.username,
              members: [{
                uid: this.urlContact.id,
                name: this.urlContact.firstname + ' ' + this.urlContact.lastname,
                role: this.urlContact.role,
                avatar: reviewerAvatarUrl,
              }, {
                uid: this.contactId,
                name: contactDoc.data().firstname + ' ' + contactDoc.data().lastname,
                role: contactDoc.data().role,
                avatar: ownerAvatarUrl,
              }],
            }, {merge: true})
            await this.uploadFiles(this.contactId, this.engagementId, surveyAnswerData.content);
            engagementCollection.doc(addedEngagementDocRef.id).collection('questionaries').doc('quick_quote').set(questionariesDoc).then(addedQuestionariesDocRef => {
                if (process.env.VUE_APP_WORDPRESS_QUOTE_REQUESTED) {
                  window.location.href = process.env.VUE_APP_WORDPRESS_QUOTE_REQUESTED + '?qqtype=' + this.surveyName.toLowerCase()
                } else {
                  this.step = _step;
                  this.nextStepLoading = false;
                }
            }).catch((addQuestionariesDocError) => {
                console.log('addQuestionariesDocError', addQuestionariesDocError);
                this.nextStepLoading = false;
            });
        }).catch(addEngagementDocError => {
            console.log('addEngagementDocError', addEngagementDocError);
            this.nextStepLoading = false;
        });
    }

    private getFileUrl(path) {
      const storageRef = firebase.storage().ref()
      return storageRef.child(path).getDownloadURL().then((url) => {
        return url
      })
    }

    private onEmailBlur() {
        const self: any = this;
        const email = self.contactForm.email;
        if (email) {
            if (this.emailRe.test(email)) {
                this.emailState = true;
            } else {
                this.emailState = false;
                this.emailWarningText = this.$t('invalidEmailWarning').toString();
            }
        } else {
            this.emailState = false;
            this.emailWarningText = this.$t('emptyEmailWarning').toString();
        }
    }

    private onFirstNameBlur() {
        const self: any = this;
        if (self.contactForm.firstname) {
            this.firstNameState = true;
        } else {
            this.firstNameState = false;
            this.firstNameWarningText = this.$t('emptyFirstnameWarning').toString();
        }
    }

    private onLastNameBlur() {
        const self: any = this;
        if (self.contactForm.lastname) {
            this.lastNameState = true;
        } else {
            this.lastNameState = false;
            this.lastNameWarningText = this.$t('emptyLastnameWarning').toString();
        }
    }

    private signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.nextStepLoading = true;
      this.signInWithPopup(provider);
    }

    private signInWithFacebook() {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.nextStepLoading = true;
      this.signInWithPopup(provider);
    }

    private generateRandomString(length) {
      const result = [] as any;
      const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }
      return result.join('');
    }

    private signInWithPopup(provider) {
      firebase.auth().signInWithPopup(provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        this.contactId = user.uid;
        this.contactForm.email = user.email;
        this.contactForm.firstname = user.displayName.split(' ')[0];
        this.contactForm.lastname = user.displayName.split(' ')[1];
        this.nextStepLoading = false;
        this.isSignInWith = true;
        this.passwordState = true;
        this.repeatPasswordState = true;
        this.onEmailBlur();
        this.onFirstNameBlur();
        this.onLastNameBlur();
      }).catch((error) => {
        console.log('signInWithError', error)
        this.nextStepLoading = false;
      });
    }

    private submitData() {
        (this as any).$analytics.logEvent('quote_next_page');
        this.nextStepLoading = true;
        const clonedForm: any = Object.assign({}, this.contactForm)
        const self: any = this;
        if (!self.emailState) {
            this.emailState = false;
            this.emailWarningText = this.emailWarningText || this.$t('emptyEmailWarning').toString();
            this.nextStepLoading = false;
            return;
        }
        if (!self.firstNameState) {
            this.firstNameState = false;
            this.emailWarningText = this.emailWarningText || this.$t('emptyEmailWarning').toString();
            this.nextStepLoading = false;
            return;
        }
        if (!self.lastNameState) {
            this.lastNameState = false;
            this.emailWarningText = this.emailWarningText || this.$t('emptyEmailWarning').toString();
            this.nextStepLoading = false;
            return;
        }
        delete clonedForm.user_repeat_password;
        if (this.isSignInWith) {
            clonedForm.owner_uid = this.contactId;
            clonedForm.role = 'Client';
            clonedForm.created_on = new Date().toUTCString();
            clonedForm.created_by = this.contactId;
            delete clonedForm.user_password;
            firebase.firestore().collection('contacts').doc(this.contactId).set(clonedForm).then((docRef) => {
                const fullName = this.getFullName(clonedForm.firstname, clonedForm.middlename, clonedForm.lastname);
                sessionStorage.setItem('fullName', fullName);
                this.createEngagementAndQuestionaries(this.contactId, clonedForm.email, fullName, 2);
            }).catch((error) => {
                this.nextStepLoading = false;
            })
        } else {
            clonedForm.user_password = this.generateRandomString(8);
            firebase.auth().createUserWithEmailAndPassword(clonedForm.email, clonedForm.user_password).then((response) => {
                // 如果email不存在则创建user、contact、engagement
                const owner_uid = response.user.uid;
                clonedForm.owner_uid = owner_uid;
                clonedForm.role = 'Client';
                clonedForm.created_on = new Date().toUTCString();
                clonedForm.created_by = owner_uid;
                delete clonedForm.user_password;
                firebase.firestore().collection('contacts').doc(owner_uid).set(clonedForm).then((docRef) => {
                    const fullName = this.getFullName(clonedForm.firstname, clonedForm.middlename, clonedForm.lastname);
                    sessionStorage.setItem('fullName', fullName);
                    this.createEngagementAndQuestionaries(owner_uid, clonedForm.email, fullName, 2);
                }).catch((error) => {
                    this.nextStepLoading = false;
                })
            }).catch((error) => {
                // 如果email已存在则只创建engagement
                console.log('createUserWithEmailAndPasswordError', error)
                if (error.code.indexOf('email-already-in-use') != -1) {
                    this.isEmailExists = true;
                    axios({
                        method: 'post',
                        url: '/getUserByEmail',
                        data: {
                            email: clonedForm.email,
                        },
                    }).then((response) => {
                        const uid = response.data.user.uid;
                        axios({
                            method: 'post',
                            url: '/generateCustomToken',
                            data: {
                                uid,
                            },
                        }).then((response) => {
                            firebase.auth().signInWithCustomToken(response.data.token).then((response) => {
                                firebase.firestore().collection('contacts').doc(uid).get().then((response) => {
                                    const fullName = this.getFullName(response.data().firstname, response.data().middlename, response.data().lastname);
                                    sessionStorage.setItem('fullName', fullName);
                                    this.createEngagementAndQuestionaries(uid, clonedForm.email, fullName, 2);
                                })
                            })
                        }).catch((error) => {
                            this.nextStepLoading = false;
                        })
                    }).catch((error) => {
                        this.nextStepLoading = false;
                    })
                }
            });
        }
    }

    private agreementHTMLFilter(html: string) {
        return html.replace('{{years}}', this.years).replace('{{price}}', this.price)
    }

}

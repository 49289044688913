import {ListableProperty} from '@/util/annotation/DataDescriptor';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {Util} from '@/util/Util';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class RadioYesNoQuestion extends AbstractQuestion<object> {

    @ListableProperty
    public yesSelection: boolean = false;

    @ListableProperty
    public noSelection: boolean = true;

    public selected: any = 'noSelection';

    public label: string = 'default';

    public fromObject(source: any): RadioYesNoQuestion {
        Util.deepCopy(source, this);
        return this;
    }

    public getCopy(): object {
        return {
            selected: this.selected,
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const data = new QuestionariesEntry(comp).fromObject(this);
        if (this.yesSelection) {
            data.content = true;
        } else if (this.noSelection) {
            data.content = false;
        } else {
            data.useful = false;
        }
        return data;
    }

    public accept(answer: QuestionariesEntry): void {
        if (answer.content === true) {
            this.yesSelection = true
            this.noSelection = false
        } else {
            this.yesSelection = true
            this.noSelection = false
        }
    }

}
































import {Prop, Component, Vue} from 'vue-property-decorator';
import PubSub from 'pubsub-js';

@Component({
    components: {
    },
})
export default class FileQuestionView extends Vue {
    @Prop()
    protected question!: any;

    protected tempFileList: any = [];

    protected onFilesInput() {
        if (this.question.content) {
            this.question.content = this.question.content.concat(this.tempFileList)
        } else {
            this.question.content = this.tempFileList
        }
        PubSub.publish('uploadedFile', {fileList: this.question.content})
    }
    
    protected onRemoveFile(fileIndex) {
        this.question.content.splice(fileIndex, 1)
    }

    private formatNames(files) {
        if (files.length === 1) {
        return files[0].name
        } else {
        return `${files.length} files selected`
        }
    }
}

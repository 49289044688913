import {ListableProperty} from '@/util/annotation/DataDescriptor';
import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {IDataObject} from '@/util/IDataObject';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export enum DataType {
    CheckBox = 'CheckBox',
    TextField = 'TextField',
    Selection = 'Selection',
    RadioButton = 'RadioButton',
}

class GroupedTemplate implements IDataObject<GroupedTemplate> {
    public type: DataType = DataType.CheckBox;
    public fieldList: string[] = [];
    public placeHolder: string = '';
    public prices: any = {};
    public fromObject(source: any): GroupedTemplate {
        return Util.deepCopy(source, this);
    }
    public getCopy(): object {
        return Util.deepCopy(this, {});
    }
}

export class GroupedBisnessQuestion extends AbstractQuestion<GroupedBisnessQuestion> {

    public label: string = 'default';

    @ListableProperty
    public currentYearSelection: boolean = false;

    @ListableProperty
    public prevYearSelection: boolean = false;

    @ListableProperty
    public prevTwoYearsSelection: boolean = false;

    public anySelected: boolean = false;

    public expandName: string|null = null;

    public templateFields: GroupedTemplate = new GroupedTemplate();

    public templateSource: any;

    /**
     * 用作V-Model，可从这里获取数据
     * @type {{}}
     */
    public templateFieldsData: any = {};

    /**
     * 记载templateFields的选择变化，有一个被选择，这里就会为true
     * 此结构中key是templateField的name
     * @type {{}}
     */
    public templateFieldsStatus: any = {};

    public init(): void {
        const source: any = this;
        if (this.templateFields.type === DataType.CheckBox) {
            for (const propName of source.props) {
                for (const templateName of this.templateFields.fieldList) {
                    if (this.templateFieldsData[propName] === undefined) {
                        this.templateFieldsData[propName] = {};
                    }
                    this.templateFieldsData[propName][templateName] = null;
                }
            }
            for (const templateName of this.templateFields.fieldList) {
                this.templateFieldsStatus[templateName] = false;
            }
        } else if (this.templateFields.type === DataType.Selection) {
            for (const propName of source.props) {
                this.templateFieldsData[propName] = null;
            }
        }

    }

    public fromObject(json: any): GroupedBisnessQuestion {
        Util.deepCopy(json, this);
        this.templateFields = new GroupedTemplate().fromObject(this.templateSource);
        this.init();
        return this;
    }

    public anySelectionRef(): void {
        this.price = 0;
        const obj: any = this;
        this.anySelected = false;
        if (this.currentYearSelection) {
            this.anySelected = true;
        } else if (this.prevYearSelection) {
            this.anySelected = true;
        } else if (this.prevTwoYearsSelection) {
            this.anySelected = true;
        }
        if (this.templateFields.type === DataType.CheckBox) {
            for (const name of obj.props) {
                for (const template of this.templateFields.fieldList) {
                    if (this.templateFieldsData[name][template] === true) {
                        if (this.anySelected) {
                            this.price = this.price + parseFloat(this.templateFields.prices[template]);
                        }
                    }
                }
            }
        } else if (this.templateFields.type === DataType.Selection) {
            for (const propName of obj.props) {
                if (Util.isEmptyOrNull(this.templateFieldsData[propName]) || obj[propName] === false) {
                    continue;
                }
                this.price = this.price + parseFloat(this.templateFields.prices[this.templateFieldsData[propName]]);
            }
        }
        if (!this.anySelected) {
            this.price = 0;
        }
    }

    public getCopy(): object {
        this.anySelectionRef();
        return {
            label: this.label,
            currentYearSelection: this.currentYearSelection,
            prevYearSelection: this.prevYearSelection,
            prevTwoYearsSelection: this.prevTwoYearsSelection,
            onceServicePrice: this.onceServicePrice,
            anySelected: this.anySelected,
            triggerEl: this.trigger,
            data: Util.deepCopy(this.templateFieldsData, {}),
        };
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const data = new QuestionariesEntry(comp).fromObject(this);
        this.anySelectionRef();

        if (!this.anySelected) {
            data.useful = false;
            return data;
        }

        // 处理年份选择
        const obj: any = this;
        for (const item of obj.props) {
            if (obj[item]) {
                data.selectedYears.push(item)
            }
        }

        if (data.selectedYears.length == 0) {
            data.useful = false;
            return data;
        }

        const content: any = {};
        const source: any = this;
        if (this.templateFields.type == DataType.CheckBox) {
            // 价格为0，没有选择checkbox
            if (this.price == 0) {
                data.useful = false;
                return data;
            }
            // 获取和处理选择的数据
            for (const propName of source.props) {
                for (const templateName of this.templateFields.fieldList) {
                    if (content[propName] === undefined) {
                        content[propName] = [];
                    }
                    if (this.templateFieldsData[propName][templateName]) {
                        content[propName].push(templateName);
                    }
                }
            }
        } else if (this.templateFields.type == DataType.Selection) {
            let count = 0;
            for (const propName of source.props) {
                if (Util.isEmptyOrNull(this.templateFieldsData[propName]) || source[propName] === false) {
                    continue;
                }

                if (Util.isEmptyOrNull(this.templateFieldsData[propName])) {
                    continue;
                }
                const templateName = this.templateFieldsData[propName];
                content[propName] = templateName;
                count ++;
            }
            if (count == 0) {
                data.useful = false;
                return data;
            }
        } else {
            data.useful = false;
        }

        data.content = content;
        return data;
    }

    public accept(answer: QuestionariesEntry): void {
        const obj: any = this;
        obj[answer.selectedYears[0]] = true;

        for (const item of answer.selectedYears) {
            obj[item] = true;
        }

        const content = answer.content;
        if (this.templateFields.type === DataType.CheckBox) {
            for (const propName in content) {
                for (const fieldName of content[propName]) {
                    this.templateFieldsData[propName][fieldName] = true
                }
            }
        } else if (this.templateFields.type === DataType.Selection) {
            for (const propName of obj.props) {
                this.templateFieldsData[propName] = content[propName]
            }
        }

    }

    private label_currentYearSelection(): string {
        return Util.getCurrTaxYear() + '';
    }

    private label_prevTwoYearsSelection(): string {
        return (Util.getCurrTaxYear() - 2) + '';
    }

    private label_prevYearSelection(): string {
        return (Util.getCurrTaxYear() - 1) + '';
    }

    private label_moreThanCurr(comp: any): string {
        return Util.getCurrTaxYear() + ' : ' + comp.$t('quickQuote.A3');
    }

    private label_moreThan1Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 1) + ' : ' + comp.$t('quickQuote.A3');
    }

    private label_moreThan2Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 2) + ' : ' + comp.$t('quickQuote.A3');
    }

}











import {Component, Prop, Vue} from 'vue-property-decorator';
import {QuestionBoard} from '../../common/QuestionBoard';
import QuestionGroupView from './QuestionGroupView.vue';
import {Util} from '../../../../util/Util';
import {ElExpress} from '../../../../util/ElExpress';

@Component({
    components: {
        QuestionGroupView,
    },
})
export default class QuestionBoardView extends Vue {
    @Prop()
    protected questionBoard!: QuestionBoard;

    protected onToggleGroup(event: any) {
        for (const group of this.questionBoard.questionGroups) {
            if (Util.isEmptyOrNull(group.trigger)) {
                continue;
            }
            const elExpr: ElExpress = new ElExpress(group.trigger);
            const data: any = {};
            // 填充需要的数据
            elExpr.varList.forEach((varName) => {
                if (varName.indexOf('.') === -1) {
                    data[varName] = this.questionBoard.groupIdMap[varName].visible;
                } else {
                    const arrNames: string[] = varName.split('.');
                    let targetdata: any = this.questionBoard.groupIdMap[arrNames[0]];
                    if (Util.isEmptyOrNull(targetdata)) {
                        return;
                    }
                    targetdata = targetdata.questionIdMap;
                    for (let idx = 1; idx < arrNames.length; idx ++) {
                        targetdata = targetdata[arrNames[idx]];
                    }
                    data[varName] = targetdata;
                }
            });
            const result = new Function('{ return (' + elExpr.spreadForFunc(data) + ');}')();
            group.visible = result;
        }
    }
}

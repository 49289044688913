
import AbstactRadioView from './AbstractRadioQuestionView.vue';
import {Component, Watch} from 'vue-property-decorator';

@Component({})
export default class YesNoQuestionView extends AbstactRadioView {
    @Watch('question.selected')
    protected onSelectChange(val: string, old: string): void {
        this.$emit('selectionChange', {
            target: null,
            question: this.question.getCopy(),
        });
    }
}



















































import {Prop, Component, Vue} from 'vue-property-decorator';
import {ComboQuestion} from '../ComboQuestion';

@Component({
    components: {
    },
})
export default class ComboQuestionView extends Vue {
    @Prop()
    protected question!: ComboQuestion;
}

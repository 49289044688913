



























import { Component, Vue } from 'vue-property-decorator';
import { firebase } from '../util/firebase';

@Component({
  components: {
  },
})

export default class TermsOfService extends Vue {

    protected system_config: any = {
      quickquote_title: 'Pacioli2',
      firm_name: '',
    };
    protected created() {
      firebase.firestore().collection('system_config').doc('quickquote.title').get().then((doc) => {
        this.system_config.quickquote_title = doc.data().value;
      })
      firebase.firestore().collection('system_config').doc('firm.name').get().then((doc) => {
        this.system_config.firm_name = doc.data().value;
      })
    }

}

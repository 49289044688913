









import {Component, Prop, Vue} from 'vue-property-decorator';
import SelectionQuestionView from '../../selectionQuestion/view/SelectionQuestionView.vue'
import BusinessSelectionView from '../../selectionQuestion/view/BusinessSelectionView.vue';
import ComboQuestionView from '../../comboQuestions/view/ComboQuestionView.vue';
import AreaInputQuestionView from '../../inputQuestions/view/AreaInputQuestionView.vue';
import FileQuestionView from '../../fileQuestion/view/FileQuestionView.vue';
import InputQuestionView from '../../inputQuestions/view/InputQuestionView.vue';
import RadioYesNoQuestionView from '../../radionQuestion/view/YesNoQuestionView.vue';
import GroupedBisnessQuestionView from '../../selectionQuestion/view/GroupedBisnessQuestionView.vue'
import {QuestionGroup} from '../QuestionGroup';
import {Util} from '../../../../util/Util';
import {ElExpress} from '../../../../util/ElExpress';

@Component({
    components: {
        SelectionQuestionView,
        BusinessSelectionView,
        ComboQuestionView,
        AreaInputQuestionView,
        FileQuestionView,
        InputQuestionView,
        RadioYesNoQuestionView,
        GroupedBisnessQuestionView,
    },
})
export default class QuestionGroupView extends Vue {
    @Prop({type: QuestionGroup})
    protected readonly group!: QuestionGroup;

    protected onSelectChange(event: any) {
        // 问题组中出现了变化，遍历问题组并计算trigger，显示合适的问题
        this.group.questions.forEach((item) => {
            if (Util.isEmptyOrNull(item.id)) {
                return;
            }
            if (Util.isEmptyOrNull(item.trigger)) {
                return;
            }
            // trigger的el表达式
            const tirggerEl: ElExpress = new ElExpress(item.trigger);
            const data: any = {};
            // 填充需要的数据
            tirggerEl.varList.forEach((varName) => {
               if (varName.indexOf('.') === -1) {
                   data[varName] = this.group.questionIdMap[varName];
               } else {
                   const arrNames: string[] = varName.split('.');
                   let targetData: any = this.group.questionIdMap;
                   arrNames.forEach((nameIdx) => {
                       targetData = targetData[nameIdx];
                   });
                   data[varName] = targetData;
               }
            });
            // 改变显示状态
            const result = new Function('{ return ' + tirggerEl.spreadForFunc(data) + ';}')();
            item.visible = result;
        });
        this.$emit('toggle', event);
    }
}

import {Util} from '@/util/Util';
import {QuestionGroup} from '@/components/question/common/QuestionGroup';

export class QuestionBoard {
    public version: number = 0;
    public questionGroups: QuestionGroup[] = [];
    public groupIdMap: any = {};
    public add(group: QuestionGroup): void {
        if (!Util.isEmptyOrNull(group.id)) {
            this.groupIdMap[group.id] = group;
        }
        this.questionGroups.push(group);
    }

    public loadAnswers(json: any): void {

    }

}

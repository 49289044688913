import {IDataObject} from '@/util/IDataObject';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export abstract class AbstractQuestion<T> implements IDataObject<T> {
    public viewType: string = '';
    public id: string = '';
    public trigger: string = '';
    public visible: boolean = true;
    public formNeedExpr: any = {};
    public formNeed: string[] = [];
    public ticketNeedExpr: any = {};
    public ticketNeed: string[] = [];
    public onceServicePrice: number = 0;
    public price: number = 0;

    public baseTime: number|null = null;

    public abstract fromObject(source: any): T;
    public abstract getCopy(): object;
    public abstract getAsEntry(comp: any): QuestionariesEntry;
    public abstract accept(answer: QuestionariesEntry): void;

}

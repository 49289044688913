
import QuestionView from './AbstractSelectionQuestionView.vue';
import {Component, Watch} from 'vue-property-decorator';

@Component({})
export default class SelectionQuestionView extends QuestionView {

    @Watch('question.currentYearSelection')
    protected onYearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

    @Watch('question.prevYearSelection')
    protected onPrev1YearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

    @Watch('question.prevTwoYearsSelection')
    protected onPrev2YearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

    @Watch('question.prevThreeYearsSelection')
    protected onPrev3YearChange(val: boolean, valold: boolean) {
        this.question.anySelectionRef();
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }
}

























import { Component, Vue } from 'vue-property-decorator';
import { firebase } from '../util/firebase';
import {Util} from '../util/Util';

declare let gtag: Function;

@Component({
  components: {
  },
})
export default class StripePay extends Vue {

    protected stripeData: Object = {}

    protected stripeLoading: boolean = false

    protected payOfflineLoading: boolean = false

    protected price: number = 0

    protected contactId: string = '';

    protected engagementId: string = '';

    protected fullName: string = '';

    protected years: number = 0;

    protected surveyName: string = '';

    protected created() {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(recaptchaScript);

      (this as any).$analytics.logEvent('quote_accepted');
      

      this.stripeLoading = true
      this.contactId = Util.of(sessionStorage.getItem('contactId'), '');
      this.engagementId = Util.of(sessionStorage.getItem('engagementId'), '');
      this.fullName = Util.of(sessionStorage.getItem('fullName'), '');
      firebase.firestore().collection('contacts').doc(this.contactId).collection('engagements').doc(this.engagementId).get().then(async (doc) => {
          this.price = parseInt(doc.data().total_price.substring(1));
          this.years = doc.data().years
          const surveyDoc = await firebase.firestore().collection('questionaries').doc(doc.data().survey_id).get()
          this.surveyName = surveyDoc.data().name
          gtag('event', 'begin_checkout', {
            items: [
              {
                id: this.contactId,
                name: this.surveyName,
                list_name: 'not set',
                brand: 'global-accountant',
                category: 'not set',
                variant: 'not set',
                list_position: 1,
                quantity: this.years,
                price: this.price,
                checkout_option: 'stripe',
              },
            ],
          });
          fetch('/createPaymentIntent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              amount: this.price * 100,
            }),
          })
          .then((result) => {
            return result.json();
          })
          .then((data) => {
            // Set up credit card input elements
            const windowX: any = window;
            let stripe = windowX.Stripe(data.publishablekey);
            if (data.connectaccount && data.appfeepercentage) {
              stripe = windowX.Stripe(data.publishablekey, {stripeAccount: data.connectaccount});
            }
            const elements = stripe.elements();
            const card = elements.create('card', {
              style: {
                base: {
                  'color': '#32325D',
                  'fontWeight': 500,
                  'fontFamily': 'Inter UI, Open Sans, Segoe UI, sans-serif',
                  'fontSize': '16px',
                  'fontSmoothing': 'antialiased',

                  '::placeholder': {
                    color: '#CFD7DF',
                  },
                },
                invalid: {
                  color: '#E25950',
                },
              },
            });
            card.mount('#card-element');

            this.stripeData = {
              stripe,
              card,
              clientSecret: data.clientSecret,
              id: data.id,
            }

            this.stripeLoading = false
          })
      })
    }

    private payWithStripe() {
        this.stripeLoading = true
        const self: any = this;
        // Set up Pay button handler
        const data = {
          card: self.stripeData.card,
          billing_details: {
            name: self.fullName,
          },
        };

        // Initiate the payment.
        // If authentication is required, confirmCardPayment will automatically display a modal

        // Use save_payment_method to indicate that you want to save the card
        // Use setup_future_usage to tell Stripe how you plan on charging the card
        self.stripeData.stripe
          .confirmCardPayment(self.stripeData.clientSecret, {
            payment_method: data,
            setup_future_usage: 'off_session',
          })
          .then((result) => {
            if (result.error) {
              // error handling
              this.stripeLoading = false
            } else if (result.paymentIntent) {
              // 将customerId保存在contact下
              fetch('/createCustomer', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  paymentMethod: result.paymentIntent.payment_method,
                }),
              })
              .then((result) => {
                return result.json();
              })
              .then((data) => {
                firebase.firestore().collection('contacts').doc(this.contactId).set({
                  customer_id: data.customerId,
                }, {merge: true});
                this.stripeLoading = false
              })
              // 将paymentIntent保存在engagement下
              firebase.firestore().collection('contacts').doc(this.contactId).collection('engagements').doc(this.engagementId).get().then((response) => {
                let pay_list: any[] = [];
                if (response.data().payment_intent_list) {
                  pay_list = response.data().payment_intent_list;
                }
                pay_list.push(result.paymentIntent);
                firebase.firestore().collection('contacts').doc(this.contactId).collection('engagements').doc(this.engagementId).set({
                  payment_intent_list: pay_list,
                  status: 'DepositPaid',
                }, {merge: true}).then((response) => {
                  this.$emit('paymentCompleted', this.surveyName, this.years)
                  gtag('event', 'purchase', {
                    transaction_id: this.engagementId,
                    value: this.price,
                    currency: "USD",
                    tax: 0,
                    shipping: 0,
                    checkout_option: 'stripe',
                    items: [
                      {
                        id: this.contactId,
                        name: this.surveyName,
                        list_name: 'not set',
                        brand: 'global-accountant',
                        category: 'not set',
                        variant: 'not set',
                        list_position: 1,
                        quantity: this.years,
                        price: this.price,
                        checkout_option: 'stripe',
                      },
                    ],
                  });
                })
              });
            }
          });
    }

    private payOffline() {
      this.payOfflineLoading = true
      firebase.firestore().collection('contacts').doc(this.contactId).collection('engagements').doc(this.engagementId).set({
        status: 'DepositPaid',
      }, {merge: true}).then((response) => {
        this.$emit('paymentCompleted', this.surveyName, this.years)
        this.payOfflineLoading = false
      })
    }
}


import QuestionView from './AbstractSelectionQuestionView.vue';
import {Component, Watch} from 'vue-property-decorator';

@Component({})
export default class BusinessSelectionView extends QuestionView {

    @Watch('question.soleProprietor')
    protected soleProprietorChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 50;
            this.question.formNeed.push('Sch C');
        } else {
            this.question.price = this.question.price - 50;
            this.question.formNeed.splice(this.question.formNeed.indexOf('Sch C'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.singleMemberLLC')
    protected singleMemberLLCChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 50;
            this.question.formNeed.push('Sch C');
        } else {
            this.question.price = this.question.price - 50;
            this.question.formNeed.splice(this.question.formNeed.indexOf('Sch C'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.multipleMemberLLC')
    protected multipleMemberLLChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 500;
            this.question.formNeed.push('F1065');
        } else {
            this.question.price = this.question.price - 500;
            this.question.formNeed.splice(this.question.formNeed.indexOf('F1065'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.partnership')
    protected partnershipChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 500;
            this.question.formNeed.push('F1065');
        } else {
            this.question.price = this.question.price - 500;
            this.question.formNeed.splice(this.question.formNeed.indexOf('F1065'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.sCorporation')
    protected sCorporationChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 500;
            this.question.formNeed.push('F1120S');
        } else {
            this.question.price = this.question.price - 500;
            this.question.formNeed.splice(this.question.formNeed.indexOf('F1120S'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.uSCorporation')
    protected uSCorporationChange(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 500;
            this.question.formNeed.push('1120');
        } else {
            this.question.price = this.question.price - 500;
            this.question.formNeed.splice(this.question.formNeed.indexOf('1120'), 1);
        }
        this.emitChangeEvent();
    }

    @Watch('question.foreignCorporation')
    protected foreignCorporation(val: boolean, oldVal: boolean) {
        if (val === true) {
            this.question.price = this.question.price + 400;
            this.question.formNeed.push('5471');
        } else {
            this.question.price = this.question.price - 400;
            this.question.formNeed.splice(this.question.formNeed.indexOf('5471'), 1);
        }
        this.emitChangeEvent();
    }

    protected emitChangeEvent() {
        this.$emit('selectionChange', {
            target: this.emitTarget,
            question: this.question.getCopy(),
        });
    }

}

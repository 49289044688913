import {Util} from '@/util/Util';
import {AbstractQuestion} from '@/components/question/common/AbstractQuestion';
import {QuestionariesEntry} from '@/components/question/QuestionariesDocument';

export class InputQuestion extends AbstractQuestion<object> {

    public label: string = 'default';

    public content: string = '';

    public fromObject(source: any): InputQuestion {
        Util.deepCopy(source, this);
        return this;
    }

    public getCopy(): object {
        return {};
    }

    public getAsEntry(comp: any): QuestionariesEntry {
        const data = new QuestionariesEntry(comp).fromObject(this);
        data.content = this.content;
        return data;
    }

    public accept(answer: QuestionariesEntry): void {
        this.content = answer.content;
    }

    private label_howMuchCurr(comp: any): string {
       return comp.$t('quickQuote.A6-2') + Util.getCurrTaxYear();
    }

    private label_howMuch2YearAgo(comp: any): string {
        return comp.$t('quickQuote.A6-2') + (Util.getCurrTaxYear() - 1);
    }

    private label_howMuch3YearAgo(comp: any): string {
        return comp.$t('quickQuote.A6-2') + (Util.getCurrTaxYear() - 2);
    }

    private label_lblSoleCurr(comp: any): string {
        return Util.getCurrTaxYear() + ' ' + comp.$t('questions.soleProprietor') + ' : '
            + comp.$t('quickQuote.A21');
    }

    private label_lblLLCCurr(comp: any): string {
        return Util.getCurrTaxYear() + ' ' + comp.$t('questions.singleMemberLLC') + ' : '
            + comp.$t('quickQuote.A21');
    }

    private label_lblSole1Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 1) + ' ' + comp.$t('questions.soleProprietor') + ' : '
            + comp.$t('quickQuote.A21');
    }

    private label_lblLLC1Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 1) + ' ' + comp.$t('questions.singleMemberLLC') + ' : '
            + comp.$t('quickQuote.A21');
    }

    private label_lblSole2Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 2) + ' ' + comp.$t('questions.soleProprietor') + ' : '
            + comp.$t('quickQuote.A21');
    }

    private label_lblLLC2Ago(comp: any): string {
        return (Util.getCurrTaxYear() - 2) + ' ' + comp.$t('questions.singleMemberLLC') + ' : '
            + comp.$t('quickQuote.A21');
    }
}

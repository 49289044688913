/**
 * 用于处理URL的拼接的时候
 */
export class ElExpress {
    /**
     * 解析和拼接el字符串使用的正则表达式
     * @type {RegExp}
     */
    private static regexp: RegExp = /\$\{[^\^$}]+\}/g;

    /**
     * 变量组，用于需要替换的部分
     * @type {string[]}
     */
    public varList: string[] = [];

    /**
     * 原文本内容
     * @type {string}
     */
    private text: string = '';

    /**
     * 构造一个El表达式
     */
    public constructor(text: string) {
        this.text = text;
        const array: RegExpMatchArray|null = text.match(ElExpress.regexp);
        if (array !== null) {
            this.varList = array.map((item) => item.toString())
                .map((elem) => elem.substring(2, elem.length - 1));
        }
    }

    /**
     * 以给定对象展开表达式
     * @param data 数据实体
     * @returns {string} 填充数据后的表达式
     */
    public spread(data: any): string {
        let result: string = '' + this.text;
        for (const name in data) {
            if (this.varList.lastIndexOf(name) !== -1) {
                result = result.replace('\$\{' + name + '\}', data[name]);
            }
        }
        return result;
    }

    public spreadForFunc(data: any): string {
        let result: string = '' + this.text;
        for (const name in data) {
            if (this.varList.lastIndexOf(name) !== -1) {
                switch (typeof data[name]) {
                    case 'string':
                        result = result.replace('\$\{' + name + '\}', '\"' + data[name] + '\"');
                        break;
                    case 'boolean':
                    case 'number':
                        result = result.replace('\$\{' + name + '\}', data[name]);
                    default:
                        result = result.replace('\$\{' + name + '\}', data[name]);
                }
            }
        }
        return result;
    }
}

import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import StripePay from './views/StripePay.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import TermsOfService from './views/TermsOfService.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    }, {
      path: '/stripe',
      name: 'stripeView',
      component: StripePay,
    }, {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: PrivacyPolicy,
    }, {
      path: '/terms-of-service',
      name: 'termsOfService',
      component: TermsOfService,
    }, {
      path: '/:username/:qqtype',
      name: 'searchedHome',
      component: Home,
    }, {
      path: '/:ctxid/view',
      name: 'quoteView',
      component: Home,
    },
  ],
});

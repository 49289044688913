import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta'

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'en',
    messages: {
        zh: require('./assets/language/zh.json'),
        en: require('./assets/language/en.json'),
    },
});

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.use(VueMeta);

import { firebase } from './util/firebase'
Vue.prototype.$analytics = firebase.analytics();

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import {IDataObject} from '@/util/IDataObject';
import {Util} from '@/util/Util';
import {QuestionBoard} from '@/components/question/common/QuestionBoard';
import {Vue} from 'vue/types/vue';

export class QuestionariesDocument implements IDataObject<QuestionariesDocument> {

    private questionsNameEntryMap: any = {};

    private version: number = 0;

    private utc: number = 0;

    private component: Vue|null = null;

    /**
     * 需要Vue组件进行L18N的国际化
     * 只要是带L18N的Vue组件都行，哪里的Vue都没有关系
     * @param {Vue} component
     */
    public mountedVue(component: Vue) {
        this.component = component;
    }

    public fromObject(source: QuestionBoard): QuestionariesDocument {
        if (this.component == null) {
            throw new Error('需要VueComponent进行国际化。')
        }
        this.version = source.version;
        this.utc = new Date().getTime();
        for (const group of source.questionGroups) {
            if (!group.visible) {
                continue;
            }
            this.questionsNameEntryMap[group.id] = {};
            for (const question of group.questions) {
                if (!question.visible) {
                    continue;
                }
                const entry = question.getAsEntry(this.component);
                if (entry.useful) {
                    const data: any = entry.getCopy();
                    delete data.useful;
                    this.questionsNameEntryMap[group.id][data.questionId] = data;
                }
            }
        }
        return this;
    }

    public getCopy(): object {
        const data = {
            questionVersion: this.version,
            baseDate: this.utc,
            answerContent: this.questionsNameEntryMap,
        }
        return Util.deepCopy(data, null);
    }

}

export class QuestionariesEntry implements IDataObject<QuestionariesEntry> {

    public static loadJson(json: any): QuestionariesEntry {
        const data = new QuestionariesEntry(null);
        Util.deepCopy(json, data);
        return data;
    }

    public questionName: string = '';
    public selectedYears: string[] = [];
    public content: any;
    public formNeeded: string[] = [];
    public ticketNeeded: string[] = [];
    public price: number = 0;

    public questionId: string = '';

    public useful: boolean = true;

    private component: any = null;

    /**
     * 需要Vue组件进行L18N的国际化
     * @param {Vue} component
     */
    public constructor(component: any) {
        this.component = component;
    }

    public fromObject(source: any): QuestionariesEntry {
        if (this.component == null) {
            throw new Error('需要Vue进行L18N国际化')
        }
        const obj: any = source;
        if (typeof obj.label_default === 'function') {
            this.questionName = obj.label_default(this.component);
        } else if (obj.label.startsWith('#')) {
            this.questionName = obj.label.replace('#', '');
        } else {
            this.questionName = this.component.$t(obj.label)
        }

        for (const form of source.formNeed) {
            this.formNeeded.push(form);
        }
        for (const ticket of source.ticketNeed) {
            this.ticketNeeded.push(ticket);
        }
        this.price = source.price;
        this.questionId = source.id;
        return this;
    }

    public getCopy(): object {
        delete this.component;
        return Util.deepCopy(this, {});
    }

}

import {Util} from '@/util/Util';

export function ListableProperty(target: any, propertyKey: string) {
    const descriptor: any = Object.getOwnPropertyDescriptor(target, 'props');
    if (!Util.isEmptyOrNull(descriptor)) {
        const list: string[] = descriptor.get();
        list.push(propertyKey);
        descriptor.set(list);
    } else {
        let value: string[] = [];
        value.push(propertyKey);
        Object.defineProperty(target, 'props', {
            get: (): string[] => value,
            set: (val: string[]) => (value = val),
        });
    }
}

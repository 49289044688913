import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DBURL,
    projectId: process.env.VUE_APP_FIREBASE_PID,
    storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_ID,
    appId: process.env.VUE_APP_FIREBASE_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MID
}

const firebaseApp = firebase.initializeApp(config)
firebase.analytics()
export { firebase, firebaseApp }
export default firebaseApp

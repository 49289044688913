















































import {Prop, Component, Vue} from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class AreaInputQuestionView extends Vue {
    @Prop()
    protected question!: any;
}
